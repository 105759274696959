<template>
  <!-- 是否同意协议弹框 -->
  <div class="agreePopup">
    <van-dialog v-model:show="seeDialog" :show-confirm-button="false">
    <div class="dialogCont">
      <img src="./img/dialogPng.png" alt="" />
      <p class="title">温馨提示</p>
      <p class="content">
        我们非常重视隐私和个人信息保护请您认真阅读<span
          class="protocolList"
          @click="toloanProtocol"
          v-for="(item, index) in protocolList"
          :key="index"
          >{{ "《" + item.key + "》" }}</span
        >的全部条款，接受全部条款后再开始使用我们的服务
      </p>
      <p class="twoBtn">
        <button class="noAgree" @click="noAgree">不同意</button>
        <button class="agree" @click="agree">同意</button>
      </p>
    </div>
  </van-dialog>
  </div>

</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { routerTag } from "@/utils";
import APP from '@/utils/App';
import API from '@/server/api.js';
export default {
    props:{
        isShow:Boolean
    },
  setup(props,{emit}) {
    // const emit = defineEmits(["seeDialog"]);
    const state = reactive({
      userPhone: "",
      seeDialog: true,
      protocolList: [], // 协议列表
    });
    // 获取用户协议
    const getProtocols = async () => {
      const protocolList = await API.getProtocol({});
      if(protocolList.code==200){
      state.protocolList = protocolList.protocolList;
      }
      // console.log(protocolList, "999999");
    };
    // 点击不同意，关闭对话框
    const noAgree = () => {
      state.seeDialog = false;
      emit('changeStatus',{seeDialog:false});
    };
    // 点击同意，跳转到身份验证
    const agree = () => {
      API.alreadyProtocol({ phone: state.userPhone });
      APP.JUMP_TO(`${routerTag}auth`);
      state.seeDialog = false;
    };
       // 去协议列表
    const toloanProtocol = () => {
      APP.JUMP_TO(`${routerTag}loanProtocol`)
    }
    onMounted(() => {
    console.log(props.isShow,'909090');
      state.userPhone = localStorage.getItem("cellPhone");
      state.seeDialog = props.isShow;
      getProtocols();
    });
    return {
      ...toRefs(state),
      noAgree,
      agree,
      toloanProtocol
    };
  },
};
</script>

<style>
.agreePopup  .van-dialog {
  height: 717px;
  width: 655px;
  background-color: transparent;
}

.agreePopup .van-popup {
  overflow-y: none;
}

.agreePopup .van-overlay {
  z-index: 0;
}
</style>
<style scoped>

.dialogCont {
  margin: 0 auto;
  width: 655px;
  height: 717px;
  position: relative;
}

.dialogCont img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dialogCont .title {
  font-size: 38px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FF6A00;
  position: absolute;
  z-index: 999;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.dialogCont .content {
  margin: 0 101px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #848484;
  position: absolute;
  z-index: 999;
  top: 210px;
  line-height: 37px;
}

.dialogCont .content .protocolList {
  color: #FF6A00;
}

.dialogCont .twoBtn {
  position: absolute;
  top: 560px;
  display: flex;

}

.dialogCont .twoBtn button {
  width: 217px;
  height: 68px;
  border-radius: 34px;
  border: 1px solid #8486F8;
}

.dialogCont .twoBtn button.agree {
  background: linear-gradient(180deg, #D0BADF 0%, #7370F6 100%);
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 37px;
}

.dialogCont .twoBtn .noAgree {
  background: #FFFFFF;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 37px;
  color: #8486F8;
  margin-left: 82px;
  margin-right: 53px;
}
</style>