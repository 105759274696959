import { onMounted } from 'vue'
import APP from '@/utils/App'

export function useReload (callback) {
  onMounted(() => {
    if (!APP.BROWSER.android) {
      APP.PAGE_WILL_LOAD(function () {
        window.location.reload()
      })
    } else {
      APP.PAGE_WILL_LOAD(function () {
        callback && callback()
      })
    }
  })
}
