<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="onRefresh"
  >
    <!-- <van-nav-bar safe-area-inset-top /> -->
    <div class='new-pages'>
        <!-- 头部 -->
        <div class="new-header">
            <div class="backLast" @click="backLast">
                <van-icon name="arrow-left" size="30" />
            </div>
            <div class="my-icon">
                <img @click="toMy" src="./img/my.png" alt="" />
            </div>
        </div>
        <!-- banner -->
        <div class='new-banner'>
            <img src="./img/bgc.png" alt="" />
        </div>
        <!-- 卡片 -->
        <div class='new-card'>
            <div class="card-main">
              <div class="card-text" v-if="creditState != 0">最高可借(元)</div>
              <div>
                <div class="card-num" v-if="creditState == -1">100,000.00</div>
                <div  v-else>
                  <div class="card-num" v-if="creditState != 0">{{ $filters.formatMoney(loanAmount, 2) }}</div>
                  <div class="card-num" v-else></div>
                </div>
                
              </div>
              <div>
                  <div class="card-btn-dis"  v-if="creditState == 0 || creditState == 3|| creditState == 2|| creditState == 4">
                      {{stateStr}}
                  </div>
                  <div class="card-btn" v-else  @click="goapply">
                      {{stateStr}}
                  </div>
              </div>
               
            </div>
        </div>
        <!-- 账单 -->
        <div class='new-bill' >
            <!-- 存在账单 -->
            <div class='bill-card' v-if="otherInfo!=null">
                <div class='card-main'>
                    <div class='main-header'><span>{{ otherInfo.date }}</span> 需还款(元)</div>
                    <div class='mian-line'>
                        <div class='line-l'>{{ $filters.formatMoney(otherInfo.planAmt, 2) }}</div>
                        <div class='line-r' @click="Payment()">
                            <span>去还款</span>
                            <van-icon class="arrow" name="arrow" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 无账单 -->
            <div class='bill-null' v-else>
                <div class='null-text'>账单金额(元)</div>
                <div class='null-text-s'>暂无借款</div>
            </div>
        </div>
        <!-- 上传视频-->
       <!-- <div class="upload-viode" @click="goviode">上传视频</div> -->
        <!--签署协议弹框-->
        <template v-if="seeDialog">
            <Agree-popup
            :isShow="seeDialog"
            @changeStatus="changeStatus"
            ></Agree-popup>
        </template>
        <!-- 授权手机号弹框 -->
        <template v-if="stock == -1">
            <Mobile-accre @changeStock="changeStocks"></Mobile-accre>
        </template>
    </div>
  </van-pull-refresh>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import { useReload } from "@/mixin/reload";
import APP from "@/utils/App";
import API from "@/server/api.js";
import { routerTag } from "@/utils";
import { Toast } from "vant";
import AgreePopup from "../../components/AgreePopup/index.vue";
import MobileAccre from "../../components/MobileAccre/index.vue";
export default {
  components: { AgreePopup, MobileAccre },
  setup() {
    onUnmounted(() => {
      try {
        document.removeEventListener("ScanEvent", callBack, true);
      } catch (err) {
        console.log(err, "移除事件那啥");
      }
    });
    const state = reactive({
      stock: null, //用户手机号授权
      seeDialog: false,
      creditState: null,
      creditAmt: "",
      creditNo: "",
      userPhone: "",
      isLoading: false,
      protocolNeed: null,
      loanAmount: "",
      backdate: "",
      amount: "",
      loanNo: "",
      isWithdrawal: null,
      protocolList: [], // 协议列表
      otherInfo: null,
      stateStr:'',//btn按钮
      realNameState:0,//是否实名
    });

    // 返回到当前页面的时候更新数据
    useReload(() => {
      console.log("调用");
      userInfo();
      signProtocol();
    });
    //子传父（协议弹框传过来的seeDialog）
    const changeStatus = (val) => {
      console.log(val, "子组件");
      state.seeDialog = val.seeDialog;
    };
    //授权手机号传值
    const changeStocks = (val) => {
      console.log(val, "子组件");
      state.stock = val.stock;
    };
    onMounted(async () => {
      APP.DRAGBACKENABLE(false); //禁止左滑
      APP.SET_HEADER(false); //不显示标题
      state.userPhone = localStorage.getItem("cellPhone");
      await userInfo(); // 初始化信息
      await signProtocol(); // 是否展示协议
      document.addEventListener("ScanEvent", callBack, true);
      state.stock = localStorage.getItem("stock");
    });
    const changbtn=(e)=>{
      console.log(e,'视频上传')
    };
    // 刷新
    const onRefresh = () => {
      state.isLoading = false;
      userInfo();
    };
    const gotoPage = () => {
      //第一次，没有签署协议
      if (state.protocolNeed) {

        console.log(state.protocolNeed,'protocolResult22')
        state.seeDialog = true;
      } else {
        //签署过了，直接跳到身份认证
        console.log(state.protocolNeed,'protocolResult666')
        APP.JUMP_TO(`${routerTag}auth`);
      }
    };
    // 是否需要签署协议
    const signProtocol = async () => {
      const res = await API.issignProtocol({
        phone: state.userPhone,
      });
      const {result }=res
      console.log(result,'xieyi') 
      state.protocolNeed = result.need;
       
         
     
        // console.log(state.protocolNeed,'protocolResult')
    };
    // 去授信
    const gotoCredit = () => {
      APP.JUMP_TO(`${routerTag}infoNavigation?key=1`);
    };
    // 预授信（扫bd二维码）
    const gotoScan = () => {
      APP.JUMP_SCAN();
    };

    // 监听扫一扫的回调
    async function callBack(res) {
      const hasScan = localStorage.getItem("indexScan");
      if (hasScan !== null && hasScan === "true") return;
      if (res) {
        const result = JSON.parse(res.data);
        const data = {};
        data.bdNo = result.bd_no;
        data.partnerNo = result.partnerNo;
        data.partnerName = result.partnerName;
        data.productName = state.productName;
        data.userId = state.userId;
        await API.scanBd(data);
        localStorage.setItem("indexScan", "true");
        Toast.success("识别成功");
        await userInfo();
        document.removeEventListener("ScanEvent", callBack, true);
      } else {
        Toast.fail("扫码信息获取失败");
      }
    }

    // 返回上一层
    const backLast = () => {
      APP.BACK(-1);
    };
    // 补充资料
    const gotosupplement = () => {
      APP.JUMP_TO(`${routerTag}supplementImg`);
    };
    // 去我的页面
    const toMy = () => {
      APP.JUMP_TO(`${routerTag}myself`);
    };
    // 去还款-账单详情 6
    const Payment = () => {
      // console.log(111111);
      const loanNo= localStorage.getItem("loanNo");
      APP.JUMP_TO(`${routerTag}billDetail?loanNo=${loanNo}`);
    };
    // 提额
    const toWithdraw = () => {
      if (state.isWithdrawal) {
        Toast.fail("您已经提额过了哦！");
        return false;
      } else {
        API.addMoney();
        APP.JUMP_TO(`${routerTag}infoNavigation?key=2`);
      }
    };
    const userInfo = async () => {
      const res = await API.isUser({});
      // console.log(res, "90909");
      if (res.code == 200) {
        const {
          userId,
          creditNo,
          otherInfo,
          creditState,
          isWithdrawal,
          creditAmt,
          loanNo,
          loanModelList,
          productName,
          stateStr,
          realNameState
        } = res.result;
        console.log(typeof(otherInfo),'otherInfo')
        // realNameState; 0 未实名 , 1 实名
        localStorage.setItem("userId", userId);
        localStorage.setItem("creditNo", creditNo);
        state.loanAmount = creditAmt;
        state.stateStr=stateStr
        if(realNameState!=null){
          state.realNameState=realNameState
        }
        state.otherInfo = otherInfo;
        localStorage.setItem("loanNo", loanNo);
        state.creditState = creditState;
        state.isWithdrawal = isWithdrawal;
        state.creditAmt = creditAmt;
        state.productName = productName;
        if (creditState === 0) {
          localStorage.setItem("indexScan", "false");
        }
      }
    };
    const goapply=()=>{
      
      const {realNameState,protocolNeed}=state
      console.log(realNameState,protocolNeed,'6666')
      if (protocolNeed) {
        //弹窗未签订的协议
        state.seeDialog = true;
      } else {
        //签署过了，直接跳到身份认证
        // console.log(state.protocolNeed,'protocolResult666')
        // APP.JUMP_TO(`${routerTag}auth`);
        if(realNameState==0){
          //去实名
          console.log(888)
          APP.JUMP_TO(`${routerTag}auth`);
        }else{
          if(state.creditState==1){
            APP.JUMP_TO(`${routerTag}loan`);
          }else{
            APP.JUMP_TO(`${routerTag}infoNavigation?key=1`);
          }
        
        }
      }



     
      
    };
    // 点击去分期
    const toDivmoney = () => {
      APP.JUMP_TO(`${routerTag}todivisionmoney`);
    };
    const goviode=()=>{
       APP.JUMP_TO(`${routerTag}uploadImg`)
    }
    return {
      ...toRefs(state),
      gotoPage,
      toMy,
      gotoCredit,
      gotoScan,
      gotosupplement,
      Payment,
      toDivmoney,
      onRefresh,
      backLast,
      toWithdraw,
      changeStatus,
      changeStocks,
      changbtn,
      goapply,
      goviode
    };
  },
};
</script>

<style lang="less" src="./newpages.less" scoped>
</style>

